import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "home",
        component: () =>
            import ("../views/Home.vue"),
    },
    {
        path: '/404',
        name: '404',
        component: () =>
            import ('@/views/404.vue')
    },
    {
        path: '*',
        redirect: '/404'
    }
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

export default router;