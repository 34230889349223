import Vue from "vue";
import App from "./App.vue";
import router from "./router";

import { setFontSize } from './utils/rem'

import './theme/common.scss'
Vue.config.productionTip = false;

setFontSize(document, window)

import axios from 'axios'
Vue.prototype.$axios = axios
axios.get('/static/config.json').then(
    res => {
        Vue.prototype.$api = res.data.api
        new Vue({
            router,
            render: (h) => h(App),
        }).$mount("#app");
    }
)